import { SVGProps } from 'react';

export interface IconEmployeeBadgeProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

function IconEmployeeBadge(props: IconEmployeeBadgeProps) {
	const {
		testId = 'IconEmployeeBadge',
		className,
		stopColor,
		...remainingProps
	} = props;
	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="url(#icon-employee-badge-gradient)"
			viewBox="0 0 48 48"
			className={className}
			aria-label="Employee Badge Icon"
			{...remainingProps}
		>
			<path d="M22.5 3.75a3 3 0 0 0-3 3v.75H15a4.5 4.5 0 0 0-4.5 4.5v24a4.5 4.5 0 0 0 4.5 4.5h18a4.5 4.5 0 0 0 4.5-4.5V12A4.5 4.5 0 0 0 33 7.5h-4.5v-.75a3 3 0 0 0-3-3h-3ZM15 10.5h18c.83 0 1.5.67 1.5 1.5v24c0 .83-.67 1.5-1.5 1.5H15a1.5 1.5 0 0 1-1.5-1.5V12c0-.83.67-1.5 1.5-1.5Zm9 3c-1.47 0-2.65 1.3-2.65 2.93 0 1.61 1.19 2.92 2.65 2.92s2.65-1.3 2.65-2.92c0-1.62-1.18-2.93-2.65-2.93Zm0 7.08c-1.3 0-2.6.28-3.95 1.26-.63.45-1.73 1.59-1.73 2.89 0 .7.72.79.94.79h9.48c.22 0 .94-.09.94-.79 0-1.3-1.1-2.44-1.73-2.9A6.41 6.41 0 0 0 24 20.59Zm-5.63 7.92a1.12 1.12 0 1 0 0 2.25h11.25a1.12 1.12 0 1 0 0-2.25H18.38Zm1.5 3.75a1.12 1.12 0 1 0 0 2.25h8.25a1.12 1.12 0 1 0 0-2.25h-8.25Z" />
			<defs>
				<linearGradient
					id="icon-employee-badge-gradient"
					x1="18.6975"
					y1="-31.4688"
					x2="62.7352"
					y2="-15.408"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={stopColor ?? '#FB923C'} />
					<stop offset="0.5" stopColor={stopColor ?? '#EC4899'} />
					<stop offset="1" stopColor={stopColor ?? '#0EA5E9'} />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default IconEmployeeBadge;

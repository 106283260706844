import { SVGProps } from 'react';

export interface IconThreeProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconThree = (props: IconThreeProps) => {
	const { testId = 'IconThree' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 34 34"
			fill="none"
			aria-label="number three"
			{...props}
		>
			<path d="M16.5.5C7.387.5 0 7.888 0 17c0 9.113 7.388 16.5 16.5 16.5C25.613 33.5 33 26.113 33 17S25.613.5 16.5.5Zm0 3C23.956 3.5 30 9.544 30 17s-6.044 13.5-13.5 13.5S3 24.456 3 17 9.044 3.5 16.5 3.5Zm-.073 7.43c-2.054 0-3.375.95-3.89 2.087-.122.274-.178.507-.178.78 0 .59.347.985 1.024.985.548 0 .83-.202 1.096-.75.378-.822.973-1.233 1.957-1.233 1.175 0 1.804.621 1.804 1.556 0 .95-.79 1.61-2.007 1.61h-.474c-.588 0-.928.354-.928.862 0 .524.34.87.928.87h.507c1.41 0 2.24.629 2.232 1.757 0 .966-.822 1.66-2.014 1.66-1.208 0-1.838-.483-2.249-1.297-.241-.443-.547-.637-1.03-.637-.67 0-1.072.394-1.072 1.022 0 .242.056.5.185.766.547 1.144 1.91 2.102 4.126 2.104 2.7 0 4.528-1.402 4.528-3.513 0-1.603-1.12-2.619-2.853-2.78v-.047c1.37-.282 2.425-1.249 2.425-2.723 0-1.862-1.66-3.08-4.117-3.08Z" />
		</svg>
	);
};

export default IconThree;

import { SVGProps } from 'react';

export interface IconAwardRibbonProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

function IconAwardRibbon(props: IconAwardRibbonProps) {
	const {
		className,
		testId = 'IconAwardRibbon',
		stopColor,
		...remainingProps
	} = props;
	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="url(#icon-award-ribbon-gradient)"
			viewBox="0 0 48 48"
			className={className}
			aria-label="Award Ribbon Icon"
			{...remainingProps}
		>
			<path d="M24 4.465c-.866 0-1.733.372-2.333 1.115L20.02 7.62l-2.537-.682a3 3 0 0 0-3.775 2.742l-.133 2.618-2.45.939a3 3 0 0 0-1.44 4.438l1.43 2.198-1.43 2.197a3 3 0 0 0 1.44 4.438l2.45.94.133 2.616c.066 1.285.927 2.294 2.042 2.673v10.01a1.499 1.499 0 0 0 2.23 1.312L24 40.713l6.02 3.346a1.514 1.514 0 0 0 1.492-.018c.457-.27.738-.762.738-1.293V32.737c1.116-.379 1.976-1.387 2.042-2.674l.135-2.616 2.45-.939a3 3 0 0 0 1.44-4.438l-1.43-2.197 1.43-2.198a3 3 0 0 0-1.44-4.438l-2.45-.94-.135-2.617a3 3 0 0 0-3.775-2.742l-2.535.681-1.648-2.039A2.984 2.984 0 0 0 24 4.465Zm0 3.443c.217 0 .433.093.583.28l1.985 2.455a.75.75 0 0 0 .778.253l3.054-.82a.75.75 0 0 1 .944.685l.16 3.153a.752.752 0 0 0 .481.662l2.95 1.13c.446.171.62.711.36 1.11l-1.722 2.648a.75.75 0 0 0 0 .819l1.723 2.647a.75.75 0 0 1-.36 1.109l-2.95 1.13a.75.75 0 0 0-.481.662l-.162 3.153a.75.75 0 0 1-.943.687l-3.054-.82a.748.748 0 0 0-.778.252l-1.985 2.456a.749.749 0 0 1-1.166 0l-1.985-2.457a.75.75 0 0 0-.777-.251l-3.055.82a.75.75 0 0 1-.943-.687l-.161-3.153a.752.752 0 0 0-.48-.662l-2.95-1.13a.75.75 0 0 1-.361-1.11l1.723-2.646a.75.75 0 0 0 0-.82l-1.723-2.646a.751.751 0 0 1 .36-1.11l2.95-1.131a.75.75 0 0 0 .48-.662l.162-3.153a.75.75 0 0 1 .943-.685l3.054.82a.75.75 0 0 0 .778-.253l1.985-2.456a.746.746 0 0 1 .583-.28Zm0 5.215a6.758 6.758 0 0 0-6.75 6.75 6.758 6.758 0 0 0 6.75 6.75 6.758 6.758 0 0 0 6.75-6.75 6.758 6.758 0 0 0-6.75-6.75Zm0 3a3.754 3.754 0 0 1 3.75 3.75 3.754 3.754 0 0 1-3.75 3.75 3.754 3.754 0 0 1-3.75-3.75 3.754 3.754 0 0 1 3.75-3.75Zm-3.98 16.003 1.647 2.039a3 3 0 0 0 4.667 0l1.648-2.039 1.268.341V40.2l-4.522-2.512a1.504 1.504 0 0 0-1.457 0l-4.52 2.512v-7.732l1.27-.341Z" />
			<defs>
				<linearGradient
					id="icon-award-ribbon-gradient"
					x1="18.6975"
					y1="-31.4688"
					x2="62.7352"
					y2="-15.408"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={stopColor ?? '#FB923C'} />
					<stop offset="0.5" stopColor={stopColor ?? '#EC4899'} />
					<stop offset="1" stopColor={stopColor ?? '#0EA5E9'} />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default IconAwardRibbon;

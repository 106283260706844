import { SVGProps } from 'react';

export interface IconRulerProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconRuler = (props: IconRulerProps) => {
	const { testId = 'IconRuler' } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-testid={testId}
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Ruler Icon"
			{...props}
		>
			<path d="M24.375 5.151c-.835 0-1.62.326-2.21.916L6.068 22.166a3.129 3.129 0 0 0 0 4.418l7.348 7.35c.59.59 1.375.915 2.21.915s1.62-.326 2.21-.916l16.098-16.099a3.128 3.128 0 0 0 0-4.418l-7.349-7.35a3.103 3.103 0 0 0-2.209-.915Zm0 2.5c.096 0 .282.025.442.183l7.349 7.35a.626.626 0 0 1 0 .883l-16.1 16.099a.625.625 0 0 1-.883 0l-7.349-7.35a.625.625 0 0 1 0-.883l1.541-1.54 2.241 2.24a1.245 1.245 0 0 0 1.768 0 1.25 1.25 0 0 0 0-1.767l-2.241-2.241 1.982-1.982 4.116 4.116c.245.244.564.366.884.366a1.25 1.25 0 0 0 .884-2.134l-4.116-4.116 1.982-1.982 2.241 2.24a1.245 1.245 0 0 0 1.768 0 1.25 1.25 0 0 0 0-1.767l-2.241-2.241 1.982-1.982 4.116 4.116c.245.244.564.366.884.366a1.25 1.25 0 0 0 .884-2.134l-4.116-4.116 1.54-1.54a.626.626 0 0 1 .442-.184Z" />
		</svg>
	);
};
export default IconRuler;

import { SVGProps } from 'react';

export interface IconWaterDropProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconWaterDrop = (props: IconWaterDropProps) => {
	const { testId = 'IconWaterDrop', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Water Drop Icon"
			{...remainingProps}
		>
			<path
				d="M25.6 10.956c3.227 4.275 6.275 8.313 6.275 12.794 0 6.88-4.994 11.875-11.875 11.875S8.125 30.63 8.125 23.75c0-4.419 2.979-8.361 6.133-12.536 1.253-1.66 2.55-3.375 3.661-5.138a2.463 2.463 0 0 1 2.082-1.134c.853 0 1.648.43 2.109 1.147 1.064 1.653 2.298 3.287 3.49 4.867ZM20 33.125c5.52 0 9.375-3.855 9.375-9.375 0-3.644-2.802-7.357-5.77-11.288-1.031-1.367-2.093-2.773-3.07-4.22a.621.621 0 0 0-1.036.002c-1.028 1.54-2.153 3.03-3.247 4.477-2.894 3.83-5.627 7.448-5.627 11.029 0 5.52 3.855 9.375 9.375 9.375Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconWaterDrop;

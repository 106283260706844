import { SVGProps } from 'react';

export interface IconAnalogClockProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconAnalogClock = (props: IconAnalogClockProps) => {
	const { testId = 'IconAnalogClock', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			aria-label="Air Icon"
			{...remainingProps}
		>
			<path d="M12 3.75a8.25 8.25 0 1 1 0 16.5 8.25 8.25 0 0 1 0-16.5Zm0 15a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z" />
			<path d="M12 6a.75.75 0 0 1 .75.75V12a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1 0-1.5h3.75v-4.5A.75.75 0 0 1 12 6Z" />
		</svg>
	);
};

export default IconAnalogClock;

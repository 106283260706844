import { SVGProps } from 'react';

export interface IconHeartInHandGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconHeartInHandGradient = (props: IconHeartInHandGradientProps) => {
	const { testId = 'IconHeartInHandGradient', stopColor } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 33 42"
			fill="none"
			aria-label="hand heart icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M18.291.8a4.13 4.13 0 0 0-3.798 2.52 4.095 4.095 0 0 0-1.452-.27 4.13 4.13 0 0 0-4.125 4.125v12.47c-.292-.505-.552-.945-.772-1.326-1.447-2.51-3.713-2.85-5.355-2.024-1.425.72-2.565 2.573-1.486 5.603.368 1.035 3.293 8.962 4.178 10.964.702 1.583 3.879 9.13 13.56 9.12 3.109-.003 13.966-.973 13.87-16.432l.005-13.125A4.13 4.13 0 0 0 28.791 8.3c-.39 0-.767.058-1.125.16V7.174a4.13 4.13 0 0 0-4.125-4.125c-.511 0-.999.098-1.451.27A4.13 4.13 0 0 0 18.29.8Zm0 3c.62 0 1.125.505 1.125 1.125V19.55a1.5 1.5 0 0 0 3 0V7.175c0-.62.505-1.125 1.125-1.125s1.125.505 1.125 1.125V20.3a1.5 1.5 0 0 0 3 0v-7.875c0-.62.505-1.125 1.125-1.125s1.125.505 1.125 1.125l.005 13.125c0 11.085-5.951 13.482-10.88 13.475-2.83-.005-7.959-.903-10.815-7.377-.847-1.898-3.75-9.766-4.095-10.756-.398-1.118-.284-1.77.016-1.912.502-.262.952-.016 1.334.72.285.547 3.03 5.985 3.15 6.165.472.72 1.268 1.035 2.025.81.608-.18 1.26-.773 1.26-1.875V7.175c0-.62.505-1.125 1.125-1.125s1.125.505 1.125 1.125V19.55a1.5 1.5 0 0 0 3 0V4.925c0-.62.505-1.125 1.125-1.125Zm-1.056 21.15a3.07 3.07 0 0 0-3.069 3.068c0 3.068 5.625 6.494 5.625 6.494s5.625-3.426 5.625-6.494a3.07 3.07 0 0 0-3.069-3.069c-1.555 0-2.556 1.125-2.556 1.125s-1-1.125-2.556-1.125Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={10.604}
					x2={62.128}
					y1={-38.667}
					y2={-18.768}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={stopColor ?? '#FB923C'} />
					<stop offset={0.5} stopColor={stopColor ?? '#EC4899'} />
					<stop offset={1} stopColor={stopColor ?? '#0EA5E9'} />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconHeartInHandGradient;

import { SVGProps } from 'react';

export interface IconTwoProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconTwo = (props: IconTwoProps) => {
	const { testId = 'IconTwo' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 34 34"
			fill="none"
			aria-label="number two"
			{...props}
		>
			<path d="M17 .5C7.887.5.5 7.888.5 17c0 9.113 7.388 16.5 16.5 16.5 9.113 0 16.5-7.387 16.5-16.5S26.113.5 17 .5Zm0 3c7.456 0 13.5 6.044 13.5 13.5S24.456 30.5 17 30.5 3.5 24.456 3.5 17 9.544 3.5 17 3.5Zm-.113 7.559c-2.199 0-3.659 1.2-4.053 2.49a1.62 1.62 0 0 0-.088.523c0 .604.388.992 1.04.992.524 0 .805-.226 1.078-.71.42-.934 1.064-1.401 1.998-1.401 1.04 0 1.782.685 1.782 1.643 0 .838-.371 1.401-1.805 2.779l-3.464 3.31c-.492.451-.67.759-.67 1.218 0 .612.38 1.038 1.146 1.04h6.436c.629 0 1.007-.395 1.007-.967 0-.588-.378-.967-1.007-.967h-4.384v-.048l2.515-2.417c1.901-1.797 2.594-2.682 2.594-4.1 0-1.998-1.66-3.385-4.125-3.385Z" />
		</svg>
	);
};

export default IconTwo;

export interface IconQuestionBubbleProps {
	testId?: string;
	className?: string;
}

const IconQuestionBubble = (props: IconQuestionBubbleProps) => {
	const { testId = 'IconQuestionBubble', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 32 32"
			aria-label="Question Bubble Icon"
			{...remainingProps}
		>
			<path d="M16 5C9.107 5 3.5 9.475 3.5 14.977c0 3.436 2.173 6.575 5.742 8.399-1.018 1.717-1.99 3.1-2.002 3.116a1 1 0 0 0 .933 1.57c.13-.015 3.151-.39 6.836-3.141.336.021.668.032.991.032 6.892 0 12.5-4.475 12.5-9.976C28.5 9.475 22.892 5 16 5Zm0 2c5.79 0 10.5 3.578 10.5 7.977 0 4.398-4.71 7.976-10.5 7.976-.389 0-.796-.017-1.208-.054a.994.994 0 0 0-.7.206 17.033 17.033 0 0 1-3.682 2.2c.358-.575.744-1.221 1.118-1.893A.997.997 0 0 0 11.035 22C7.621 20.599 5.5 17.907 5.5 14.977 5.5 10.578 10.21 7 16 7Zm.002 2.613c-1.575 0-2.747.622-3.216 1.59a1.812 1.812 0 0 0-.219.871c0 .557.36.915.938.915.447 0 .776-.205.959-.674.234-.652.717-1.003 1.435-1.003.806 0 1.363.498 1.363 1.216 0 .674-.286 1.04-1.23 1.604-.865.505-1.311 1.077-1.311 1.941v.103c0 .6.366 1.025.988 1.025.515 0 .8-.282.912-.765l.003-.011c.095-.615.315-.93 1.29-1.494 1.032-.608 1.567-1.363 1.567-2.47 0-1.706-1.399-2.848-3.479-2.848Zm-.277 8.547c-.66 0-1.195.505-1.195 1.15 0 .644.535 1.15 1.195 1.15.666 0 1.2-.506 1.2-1.15 0-.645-.534-1.15-1.2-1.15Z" />
		</svg>
	);
};

export default IconQuestionBubble;

import { SVGProps } from 'react';

export interface IconInfoBubbleToolTipProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconInfoBubbleToolTip = (props: IconInfoBubbleToolTipProps) => {
	const {
		testId = 'IconInfoBubbleToolTip',
		className,
		'aria-label': ariaLabel = `Circle win an "i" inside`,
		...remainingProps
	} = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className={className}
			data-testid={testId}
			aria-label={ariaLabel}
			{...remainingProps}
		>
			<circle cx="12" cy="12" r="10" />
			<line x1="12" y1="16" x2="12" y2="12" />
			<line x1="12" y1="8" x2="12" y2="8" />
		</svg>
	);
};

export default IconInfoBubbleToolTip;

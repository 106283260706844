import * as React from 'react';
import { SVGProps } from 'react';

export interface IconQuestionBubbleGradientProps
	extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconQuestionBubbleGradient = (props: IconQuestionBubbleGradientProps) => {
	const { testId = 'IconQuestionBubbleGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 39 36"
			fill="none"
			aria-label="Chat Question Icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M19.5.5C9.161.5.75 7.213.75 15.465c0 5.155 3.26 9.864 8.613 12.599-1.527 2.576-2.985 4.65-3.003 4.674a1.5 1.5 0 0 0 1.4 2.354c.195-.022 4.726-.583 10.253-4.71a23.31 23.31 0 0 0 1.487.048c10.339 0 18.75-6.713 18.75-14.965C38.25 7.213 29.839.5 19.5.5Zm0 3c8.684 0 15.75 5.367 15.75 11.965S28.184 27.43 19.5 27.43c-.584 0-1.193-.026-1.812-.08a1.49 1.49 0 0 0-1.05.308c-2.061 1.598-3.977 2.634-5.523 3.3a71.254 71.254 0 0 0 1.678-2.84 1.496 1.496 0 0 0-.74-2.118C6.93 23.898 3.75 19.86 3.75 15.465 3.75 8.867 10.816 3.5 19.5 3.5Zm.003 3.92c-2.363 0-4.12.933-4.824 2.383-.22.417-.328.836-.328 1.308 0 .835.539 1.373 1.406 1.373.67 0 1.164-.307 1.439-1.01.352-.979 1.076-1.507 2.153-1.507 1.21 0 2.044.748 2.044 1.825 0 1.011-.429 1.56-1.846 2.406-1.296.758-1.966 1.615-1.966 2.912v.154c0 .9.55 1.537 1.483 1.538.773 0 1.2-.423 1.368-1.147l.004-.018c.142-.922.473-1.395 1.934-2.241 1.55-.912 2.352-2.044 2.352-3.703 0-2.56-2.1-4.273-5.22-4.273Zm-.416 12.82c-.99 0-1.792.758-1.792 1.724 0 .967.803 1.726 1.792 1.726 1 0 1.802-.759 1.802-1.726 0-.966-.802-1.724-1.802-1.724Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={12.136}
					x2={65.88}
					y1={-32.661}
					y2={-3.748}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconQuestionBubbleGradient;

import { SVGProps } from 'react';

export interface IconBackArrowProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconBackArrow = (props: IconBackArrowProps) => {
	const { testId = 'IconBackArrow', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
			fill="none"
			aria-label="Back Arrow Icon"
			{...remainingProps}
		>
			<path d="M5.638 32.313c0-8.783 7.953-16.55 18-17.863v-4.333c0-3.091 3.683-4.436 5.74-2.378l10.884 10.883c1.202 1.202 1.428 3.328 0 4.756L29.378 34.261c-2.044 2.043-5.74.678-5.74-2.423v-3.946C8.815 29.406 14.46 41.25 9.388 41.25c-2.489 0-3.75-3.007-3.75-8.937Zm3 0c0 2.503.347 4.969.51 4.969.38 0 .417-12.002 15.925-12.495.985-.032 1.565.33 1.565 1.472v5.625c0 .33.385.491.62.257L38.14 21.258c.107-.107.177-.338.001-.514L27.257 9.861c-.211-.212-.62-.094-.62.256v5.325c0 1.322-.766 1.796-1.767 1.892-9.092.867-16.232 7.482-16.232 14.979Z" />
		</svg>
	);
};

export default IconBackArrow;
